
/* Default style for the navigation bar */
.navbar{
    position: fixed;
    top: 0px;
    z-index: 1000; 
    height: 130px;
    display: flex; 
    align-items: center;
    transition: height 0.15s ease; /* Add a smooth transition effect */
}

  
  @media (max-width: 600px) {
    .navbar {
      height: 70px; 
    }
  }
  
  
  @media (min-width: 601px) and (max-width: 992px) {
    .navbar{
      height: 110px;
    }
  }

/* Style for the navigation bar when scrolled down */
.navbar.scrolled {
    height: 90px;
  }

    
@media (max-width: 600px) {
    .navbar.scrolled {
        height: 50px; 
    }
}
      
     
@media (min-width: 601px) and (max-width: 992px) {
    .navbar.scrolled{
        height: 70px;
    }
}

.navbar.scrolled .home-button {
    font-size: 40px;
    
}

.navbar-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-button {
    font-size: 50px; 
    margin-top: 10px; 
    color: black; 
    left: 10px; 
}

.company-logo{
    text-align: center;
    position: absolute;
    top: -17%;
    left: 50%; 
    transform: translateX(-50%);
}

.company-logo img {
    width: 400px; /* Default width for the logo when the navbar is not scrolled */
    height: 150px; /* Default height for the logo when the navbar is not scrolled */
    transition: width 0.3s ease, height 0.3s ease;
  }

  /* Adjust logo size for small screens when navbar is not scrolled */
@media (max-width: 600px) {
    .company-logo img {
      width: 220px; 
      height: 80px; 
    }
  }
  
  /* Adjust logo size for medium screens when navbar is not scrolled */
  @media (min-width: 601px) and (max-width: 992px) {
    .company-logo img {
      width: 300px; 
      height: 120px;
    }
  }
  
  /* Adjust smaller logo size for small screens when navbar is scrolled */
  @media (max-width: 600px) {
    .navbar.scrolled .company-logo img {
      width: 150px; 
      height: 60px;
    }
  }
  
  /* Adjust smaller logo size for medium screens when navbar is scrolled */
  @media (min-width: 601px) and (max-width: 992px) {
    .navbar.scrolled .company-logo img {
      width: 200px;
      height: 75px;
    }
  }
  
  /* Adjust smaller logo size for large screens when navbar is scrolled */
  @media (min-width: 993px) {
    .navbar.scrolled .company-logo img {
      width: 280px; 
      height: 105px;
    }
  }

.navbar-text{
    font-size: 20px;
}