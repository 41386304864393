.slide-offset {
    margin-top: 60px; /* Adjust the value as needed */
  }

  .button-offset {
    margin-top: 60px; /* Adjust the value as needed */
  }

.hp-text {
  font-size: 45px;
  font-family: Cambria;
}

.custom-font-size {
  /* Default font size for small screens */
  font-size: 28px;

  /* Adjust font size for medium screens */
  @media (min-width: 600px) {
    font-size: 40px;
  }

  /* Adjust font size for large screens */
  @media (min-width: 992px) {
    font-size: 50px;
  }
}